<template>
    <v-app id="view-appointment">

         <v-snackbar
            v-model="show_snackbar"
            :timeout="2000"
            :color="snackbar_color"
            top
            centered
            transition="slide-y-transition"
        >
            <v-icon class="mr-1">{{ snackbar_icon }}</v-icon><span class="white--text">{{ snackbar_text }}</span>
        </v-snackbar>

        <!-- View Appointment header -->
        <v-card flat>
            <v-card-title>
                <v-flex text-xs-center>
                    <h3 class="text-center font-weight-bold header-space">
                        View Appointment

                        <v-btn
                            class="page_close_btn"
                            icon
                            to="/calendar"
                        >
                            <v-icon color="black" large>mdi-close</v-icon>
                        </v-btn>

                    </h3>
                </v-flex>
            </v-card-title>
        </v-card>
        <!-- End header -->

        <v-divider></v-divider>

        <v-row no-gutters>
            <!-- Left block -->
            <v-col cols="12" sm="6" md="8">
                <v-card
                    class="mx-auto stepper-width pl-15 pr-15"
                    flat
                >
                    <!-- Show Date -->
                    <v-col
                        cols="12"
                        sm="8"
                        md="6"
                        class="mb-2"
                    >
                        <div class="mt-5">
                            <label class="text-h5 font-weight-bold"> {{ appointment_date }} </label>
                        </div>
                    </v-col>
                    <!-- End date -->

                    <!-- Service details -->
                    <v-col
                        cols="12"
                    >
                        <template v-for="(service, i) in service_details">
                            <v-row :key="i">
                                <v-col
                                    cols="12"
                                    md="2"
                                >
                                    <span class="font-weight-medium">{{ service.from_time | moment }}</span>
                                </v-col>
                                <v-col
                                    cols="12"
                                    md="8"
                                >
                                    <div class="font-weight-medium">{{ service.service_name }}</div>
                                    <span class="text--disabled">
                                        {{ duration_text(service.duration) }} with {{ service.staff_name }}
                                    </span>
                                </v-col>
                                <v-col
                                    cols="12"
                                    md="2"
                                    class="text-right"
                                >
                                    <span class="font-weight-medium">{{ $currency }}{{ service.rate }}</span>
                                </v-col>
                            </v-row>
                        </template>
                    </v-col>
                    <!-- End service details -->

                    <v-divider></v-divider>

                    <!-- Total -->
                    <v-col
                        cols="12"
                    >
                        <v-row>
                            <v-col
                                cols="12"
                                md="2"
                            >
                            </v-col>
                            <v-col
                                cols="12"
                                md="8"
                            >
                                <span class="font-weight-regular">{{ duration_text(total_duration) }}</span>
                            </v-col>
                            <v-col
                                cols="12"
                                md="2"
                                class="text-right"
                            >
                                <span class="text-h6 font-weight-bold">{{ $currency }}{{ total_amount }}</span>
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- End total -->

                    <!-- Appointment notes -->
                    <v-col
                        cols="12"
                        class="mt-6"
                        v-if="appointment_notes"
                    >
                        <p class="mb-3 text-h6 font-weight-bold">Appointment note</p>
                        <p class="text-body-1">{{ appointment_notes }}</p>
                    </v-col>
                    <!-- End appointment notes -->
                </v-card>
            </v-col>
            <!-- End left block -->

            <v-divider vertical class="vertical-divider"></v-divider>

            <!-- Right block -->
            <v-col cols="6" md="4">
                <v-card flat class="pa-7 pb-5">
                    <div>
                        <v-avatar
                            color="grey lighten-2"
                            size="60"
                            class="mr-3"
                            v-if="client == 'Default Customer'"
                        >
                            <span style="width: 32px;height: 32px;">
                                <svg viewBox="0 0 20 22" xmlns="http://www.w3.org/2000/svg"><g fill="#FFF" stroke="#101928"><path d="M.5 21c0-5.5313 4.1043-9.5 9.5-9.5s9.5 3.9687 9.5 9.5v.5H.5V21z" stroke-linecap="round" stroke-linejoin="round"></path><path d="M15.5 6c0-3.0374-2.4626-5.5-5.5-5.5S4.5 2.9626 4.5 6s2.4626 5.5 5.5 5.5 5.5-2.4626 5.5-5.5z"></path></g></svg>
                            </span>
                        </v-avatar>

                         <v-avatar class="mr-3" size="60" v-if="client != 'Default Customer'">
                            <v-icon color="#000" class="grey lighten-4" >
                                {{ client.charAt(0) }}
                            </v-icon>
                        </v-avatar>

                        <span class="text-h6 font-weight-medium">
                            {{client}}
                        </span>
                    </div>
                </v-card>

                <v-divider></v-divider>
                
                <div v-if="!sales_invoice"> 
                    <v-sheet
                        color="grey lighten-4"
                        elevation="1"
                        height="90"
                        class="d-flex align-center justify-center"
                    >
                        <div class="d-flex align-center">
                            <v-menu v-if="within_list == 1" offset-y rounded="0">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        v-bind="attrs"
                                        v-on="on"
                                        rounded
                                        color="white"
                                        height="42"
                                        min-width="266px"
                                        class="app_btn"
                                        elevation="1"
                                    >
                                        <v-avatar
                                            :color="selected_app_icon_clr"
                                            size="20"
                                            class="mr-3"
                                        >
                                            <v-icon :size="14" color="white">{{ selected_app_icon }}</v-icon>
                                        </v-avatar>
                                        <span class="text-capitalize app_status">{{ selected_app_status }}</span>
                                        <v-icon dense class="pl-3">mdi-chevron-down</v-icon>
                                    </v-btn>
                                </template>
                                <v-list class="pa-0" dense>
                                    <template v-for="(item, index) in apponitment_status">
                                        <v-list-item
                                            :key="index"
                                            link
                                            v-if="item.show"
                                        >
                                            <v-list-item-icon class="mr-0">
                                                <v-avatar
                                                    :color="item.icon_color"
                                                    size="20"
                                                >
                                                    <v-icon :size="item.icon_size" color="white">{{ item.icon }}</v-icon>
                                                </v-avatar>
                                            </v-list-item-icon>
                                            <v-list-item-title class="text-center app_font" link @click="select_app_status(item.value)">
                                                {{ item.label }}
                                            </v-list-item-title>
                                        </v-list-item>
                                        <v-divider
                                            v-if="index < apponitment_status.length - 1"
                                            :key="item.value"
                                        ></v-divider>
                                    </template>
                                </v-list>
                            </v-menu>
                            <v-card v-else color="transparent" flat>
                                <div>
                                    <v-avatar
                                        color="transparent"
                                        size="53"
                                    >
                                        <span style="width: 32px;height: 32px;">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12.042 22c-5.514 0-10-4.486-10-10s4.486-10 10-10 10 4.486 10 10-4.486 10-10 10zm0-18c-4.41 0-8 3.59-8 8s3.59 8 8 8 8-3.59 8-8-3.59-8-8-8z" fill="#e97b90"></path><path d="M16.75 8.707l-1.416-1.414-3.292 3.293L8.75 7.293 7.333 8.707 10.627 12l-3.293 3.293 1.415 1.414 3.292-3.292 3.292 3.292 1.415-1.414L13.455 12" fill="#e97b90"></path></svg>
                                        </span>
                                    </v-avatar>
                                    <span>
                                        {{ within_list }}
                                    </span>
                                </div>
                            </v-card>
                        </div>
                    </v-sheet>

                    <v-card
                        color="white"
                        flat
                        height="318"
                        tile
                    > 
                        <v-container class="px-15" fluid fill-height>
                            <v-layout align-center justify-center>
                                <div class="text-md-center">
                                    <p class="custom_search_icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 24"><path d="M20 1c.6 0 1 .4 1 1v20c0 .6-.4 1-1 1H2c-.6 0-1-.4-1-1V2c0-.6.4-1 1-1h18m0-1H2C.9 0 0 .9 0 2v20c0 1.1.9 2 2 2h18c1.1 0 2-.9 2-2V2c0-1.1-.9-2-2-2z" fill="#67768c"></path><path d="M6.5 5h-3c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h3c.3 0 .5.2.5.5s-.2.5-.5.5zM12.5 5h-3c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h3c.3 0 .5.2.5.5s-.2.5-.5.5zM18.5 5h-3c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h3c.3 0 .5.2.5.5s-.2.5-.5.5zM6.5 7h-3c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h3c.3 0 .5.2.5.5s-.2.5-.5.5zM11.5 10h-8c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h8c.3 0 .5.2.5.5s-.2.5-.5.5zM8.5 12h-5c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h5c.3 0 .5.2.5.5s-.2.5-.5.5zM7.5 15h-4c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h4c.3 0 .5.2.5.5s-.2.5-.5.5zM7.5 17h-4c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h4c.3 0 .5.2.5.5s-.2.5-.5.5zM21.5 20H.5c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h21c.3 0 .5.2.5.5s-.2.5-.5.5zM12.5 7h-3c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h3c.3 0 .5.2.5.5s-.2.5-.5.5zM18.5 7h-3c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h3c.3 0 .5.2.5.5s-.2.5-.5.5z" fill="#67768c"></path></svg>
                                    </p>
                                    <p>No invoice issued yet</p>
                                </div>
                            </v-layout>
                        </v-container>
                    </v-card>
                </div>

                <v-card
                    color="white"
                    flat
                    height="408"
                    tile
                    v-if="sales_invoice && appointment_paid == 0"
                >
                    <v-container class="px-15" fluid fill-height>
                        <v-layout align-center justify-center>
                            <div class="text-md-center">
                                <p>
                                    <v-icon size="48px">$vuetify.icons.AppointmentUnPaidSvg</v-icon>
                                </p>
                                <p class="mt-4 text-h5 font-weight-bold">Appointment Completed</p>
                                <p>
                                    Unpaid invoice 
                                    <router-link class="text-body-1" :to="'/invoice/'+sales_invoice" style="text-decoration : none;letter-spacing: 0px !important;">{{ sales_invoice }}</router-link>
                                </p>
                            </div>
                        </v-layout>
                    </v-container>
                </v-card>

                <v-card
                    color="white"
                    flat
                    height="408"
                    tile
                    v-if="sales_invoice && appointment_paid == 1"
                >
                    <v-container class="px-15" fluid fill-height>
                        <v-layout align-center justify-center>
                            <div class="text-md-center">
                                <p>
                                    <v-icon size="48px">$vuetify.icons.AppointmentPaidSvg</v-icon>
                                </p>
                                <p class="mt-4 text-h5 font-weight-bold">Appointment Completed</p>
                                <p>{{ remarks }} 
                                    <router-link class="text-body-1" :to="'/invoice/'+sales_invoice" style="text-decoration : none;letter-spacing: 0px !important;">{{ sales_invoice }}</router-link>
                                </p>
                            </div>
                        </v-layout>
                    </v-container>
                </v-card>

                <v-divider></v-divider>

                <!-- Checkout btn -->
                <v-card flat>
                    <div class="pa-6">
                        <p class="font-weight-medium text-center total_text">Total: {{ $currency }}{{ total_amount }} ({{ duration_text(total_duration) }})</p>
                        <v-row align="center">
                            <v-col
                                cols="12"
                                sm="6"
                            >
                                <div class="my-2 text-center">

                                    <v-menu top offset-y rounded="0">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                v-bind="attrs"
                                                v-on="on"
                                                large
                                                color="white"
                                                elevation="1"
                                                class="app_btn"
                                            >
                                                <span class="text-capitalize font-checkout">More options</span>
                                                <v-icon dense class="pl-3">mdi-chevron-down</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-list class="pa-0" dense>
                                            <template v-for="(item, index) in more_options">
                                                <v-list-item
                                                    :key="index"
                                                    link
                                                    :class="item.text_color"
                                                    @click="more_option_click(item.label)"
                                                >
                                                    <v-list-item-title class="text-center app_font" link>
                                                        {{ item.label }}
                                                    </v-list-item-title>
                                                </v-list-item>
                                                <v-divider
                                                    v-if="index < more_options.length - 1"
                                                    :key="item.value"
                                                ></v-divider>
                                            </template>
                                        </v-list>
                                    </v-menu>


                                </div>
                            </v-col>
                            <v-col
                                cols="12"
                                sm="6"
                                v-if="!sales_invoice"
                            >
                                <div class="my-2 text-center">
                                    <v-btn
                                        class="text-capitalize font-weight-bold px-10 py-4 font-checkout"
                                        dark
                                        color="#101928"
                                        large
                                        depressed
                                        width="167px"
                                        :to="'/checkout/'+appointment_id+'/'+$route.params.employeeId"
                                    >
                                        Checkout
                                    </v-btn>
                                </div>
                            </v-col>
                            <v-col
                                cols="12"
                                sm="6"
                                v-if="sales_invoice && appointment_paid == 1"
                            >
                                <div class="my-2 text-center">
                                    <v-btn
                                        class="app_btn text-capitalize px-10 py-4 font-checkout"
                                        color="white"
                                        elevation="1"
                                        large
                                        width="167px"
                                        :to="'/invoice/'+sales_invoice"
                                    >
                                        View Invoice
                                    </v-btn>
                                </div>
                            </v-col>
                            <v-col
                                cols="12"
                                sm="6"
                                v-if="sales_invoice && appointment_paid == 0"
                            >
                                <div class="my-2 text-center">
                                    <v-btn
                                        class="text-capitalize font-weight-bold px-10 py-4 font-checkout"
                                        dark
                                        color="#101928"
                                        large
                                        depressed
                                        width="167px"
                                        :to="'/apply_payment/'+sales_invoice"
                                    >
                                        Pay Now
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                </v-card>
                <!-- End Checkout -->

            </v-col>
            <!-- End right block -->

        </v-row>

        <!-- Cancel appointment dialog -->
		<v-dialog
			v-model="cancel_appointment_dialog"
			persistent
			max-width="450px"
			transition="dialog-bottom-transition"
            class="top-dialog"
		>
			<v-card class="block_dialog">
				<v-card-title class="p-0 text-center">
					<v-app-bar color="white" flat>
						<v-app-bar-title class="text-h5 font-weight-bold">Cancel Appointment</v-app-bar-title>
						<v-btn
							icon
							@click="cancel_appointment_dialog = false"
							class="close_dlog"
                            style="right: -32%;"
						>
							<v-icon large>mdi-close</v-icon>
						</v-btn>
					</v-app-bar>
					
				</v-card-title>
                <v-divider></v-divider>
				<v-card-text class="pb-15 mb-3">
					<v-container>
						<v-row>
							<v-col
								cols="12"
								sm="12"
								md="12"
								class="pt-5 pb-0 px-0"
							>
								<label style="line-height: 30px;" class="dialog_label">Cancellation reason</label>
								<v-select
									:items="cancel_reasons"
									item-text="label"
									item-value="value"
									v-model="selected_cancel_reason"
									return-object
								></v-select>
							</v-col>
						</v-row>
					</v-container>
				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
                    <v-spacer></v-spacer>
					<v-btn
						right
						height="42"
						color="#101928"
						dark
                        class="dialog_btn px-4"
						@click="cancel_appointment"
					>
						Cancel appointment
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<!-- End cancel appointment dialog -->
    </v-app>
</template>

<script>
import CommonApi from '@/services/CommonApi'
import moment from 'moment'

export default {
    name: "ViewAppointment",

    data: () => ({
        appointment_id : '',
        appointment_date: '',
        appointment_notes : '',
        service_details: [],
        apponitment_status : [
            { value: 'New', label: 'New Appointment', icon: 'mdi-star-outline', icon_color: 'blue darken-3', icon_size:"14", show: true },
            { value: 'Confirmed', label: 'Confirmed', icon: 'mdi-thumb-up-outline', icon_color: 'blue darken-1', icon_size:"14", show: true },
            { value: 'Arrived', label: 'Arrived', icon: 'mdi-chevron-down', icon_color: 'orange accent-2', icon_size:"19", show: true },
            { value: 'Started', label: 'Started', icon: 'mdi-chevron-right', icon_color: 'teal accent-4', icon_size:"19", show: true },
        ],
        selected_app_status: 'New Appointment',
        selected_app_icon: 'mdi-star-outline',
        selected_app_icon_clr: 'blue darken-3',
        more_options: [
            { label: 'Edit Appointment', text_color: ''  },
            { label: 'Reschedule', text_color: ''  },
            { label: 'Cancel', text_color: 'color-pink'  },
            { label: 'No Show', text_color: 'color-pink'  },
        ],
        total_duration : 0, 
        total_amount: 0,
        client:'',
        within_list: 1,
        cancel_appointment_dialog: false,
        cancel_reasons: [
            { label: 'No reason provided', value: 0 },
            { label: 'Duplicate appointment', value: 1 },
            { label: 'Appointment made by mistake', value: 2 },
            { label: 'Client not available', value: 3 }
        ],
        selected_cancel_reason: 0,
        show_snackbar: false,
        snackbar_text: '',
        snackbar_color: '',
        snackbar_icon: '',
        appointment_paid : 0,
        sales_invoice : '',
        remarks : ''
    }),
    filters: {
        moment: function (time) {
            return moment(time, ["HH:mm:ss"]).format('h:mma');
        }
    },

    methods: {
        getAppointmentDetails() {
            this.appointment_id = this.$route.params.appointment_id;
            let param = { appointment_id: this.appointment_id };
            const params = new URLSearchParams(param);
            CommonApi.get_data('innosoft_salon.api.get_appointment_details', params)
            .then(res => {
                if (res.status_code == 200) {
                    let appointment_date = res.data.date;
                    this.appointment_date = moment(appointment_date).format('dddd, D MMMM Y');

                    this.appointment_notes = res.data.notes;

                    this.service_details = res.data.services;

                    this.total_duration = res.data.total_duration;
                    this.total_amount = res.data.total_amount;
                    this.client = res.data.client;

                    this.appointment_paid = res.data.paid;
                    this.sales_invoice = res.data.sales_invoice;
                    this.remarks = res.data.remarks;

                    let app_status = res.data.status;
                    let selected_st_index = this.apponitment_status.findIndex(o => o.value == app_status);
                    if(selected_st_index != -1) {
                        this.appointment_status_change(selected_st_index, 1);
                    } else {
                        this.within_list = app_status;
                    }
                    this.change_more_options();
                }
            })
        },
        change_more_options() {
            if(this.sales_invoice) {
                if(this.appointment_paid == 0) {
                    this.more_options = [
                        { label: 'Rebook', text_color: '' },
                        { label: 'Edit Notes', text_color: '' },
                        { label: 'View Invoice', text_color: '' },
                    ]
                } else {
                    this.more_options = [
                        { label: 'Rebook', text_color: '' },
                        { label: 'Edit Notes', text_color: '' },
                    ]
                }
            } else {
                if(this.within_list == 'Cancelled') {
                    this.more_options = [
                        { label: 'Rebook', text_color: '' }
                    ]
                } else if(this.within_list == 'No Show') {
                    this.more_options = [
                        { label: 'Rebook', text_color: '' },
                        { label: 'Undo No Show', text_color: '' },
                    ]
                }
            }
        },
        more_option_click(type) {
            if(type == 'Cancel') {
                let current = moment().format('MM-DD-YYYY');
                let app_date = moment(this.appointment_date, ['dddd, D MMMM Y']).format('MM-DD-YYYY');
                if(moment(app_date).isBefore(current)) {
                    this.show_snackbar = true;
                    this.snackbar_text = "Past appointments can't be cancelled, use no show action instead";
                    this.snackbar_color = 'error';
                    this.snackbar_icon = 'mdi-alert-outline'
                } else {
                    this.cancel_appointment_dialog = true;
                }
            } else if(type == 'No Show') {
                this.select_app_status(type);
            } else if(type == 'Undo No Show') {
                this.select_app_status(type);
            } else if(type == 'Edit Appointment') {
                this.$router.push('/edit-appointment/'+this.appointment_id)
            } else if(type == 'View Invoice') {
                this.$router.push('/invoice/'+this.sales_invoice)
            } else if(type == 'Reschedule') {
                let app_date = moment(this.appointment_date, ['dddd, D MMMM Y']).format('YYYY-MM-DD');
                this.$router.push('/calendar?app='+this.appointment_id+'&date='+app_date+'&type=reschedule')
            }
        },
        duration_text(durations) {
            let duration_text = '';
            if(durations > 55) {
                var hours = Math.floor(durations / 60);          
                var minutes = durations % 60;

                duration_text = hours+'h '+minutes+'min';
            } else {
                duration_text = durations+'min';
            }
            return duration_text;
        },
        select_app_status(type) {
            // if(type == 'New' || type == 'Confirmed' || type == 'Arrived' || type == 'Started' || type == 'No Show' || type == 'Undo No Show') {
            var types = ['New','Confirmed','Arrived','Started','No Show','Undo No Show']
            if(types.includes(type)){
                const post_data = new FormData();
                post_data.append("id", this.appointment_id);
                if(type == 'Undo No Show') {
                    post_data.append("status", 'New')
                }else{
                    post_data.append("status", type);
                }
                CommonApi.post_data('innosoft_salon.api.update_status', post_data)
                .then(res => {
                    if(res) {
                        this.show_snackbar = true;
                        this.snackbar_text = 'Appointment set to '+type;
                        this.snackbar_color = 'success';
                        this.snackbar_icon = 'mdi-check-circle-outline';

                        let app_status = type;
                        let selected_st_index = this.apponitment_status.findIndex(o => o.value == app_status);
                        let change_type = 1;
                        if(type == 'No Show' || type == 'Undo No Show') {
                            change_type = 2;
                        }
                        this.appointment_status_change(selected_st_index, change_type);
                        // setTimeout(function () {
                        //     this.$router.push('/calendar');
                        // }.bind(this), 3000)

                    }
                });
            }
        },
        appointment_status_change(index, change_type) {
            if(change_type == 1) {
                this.apponitment_status.map(a => a.show=true);

                this.selected_app_status = this.apponitment_status[index].label;
                this.selected_app_icon = this.apponitment_status[index].icon;
                this.selected_app_icon_clr = this.apponitment_status[index].icon_color;
                this.apponitment_status[index].show = false;
            } else {
                location.reload();
            }
            
        },
        cancel_appointment() {
            this.cancel_appointment_dialog = false;
            if (this.selected_cancel_reason.label && this.appointment_id) {
                const post_data = new FormData();
                post_data.append("id", this.appointment_id);
                post_data.append("reason", this.selected_cancel_reason.label);
                CommonApi.post_data('innosoft_salon.api.cancel_appointment', post_data)
                .then(res => {
                    if(res) {
                        this.show_snackbar = true;
                        this.snackbar_text = 'Appointment Cancelled Successfull...';
                        this.snackbar_color = 'success';
                        this.snackbar_icon = 'mdi-check-circle-outline';

                        setTimeout(function () {
                            this.$router.push('/calendar');
                        }.bind(this), 3000)

                    }
                });
            }

        }
    },
    mounted() {
        this.getAppointmentDetails();
    },
}
</script>
